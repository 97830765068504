// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app\\utils\\partner-storage.ts"
);
import.meta.hot.lastModified = "1734040156742.7192";
}
// REMIX HMR END

import type { PartnerConfig } from '~/types/partner'

export const PARTNER_CONFIG_KEY = 'partnerConfig'

export const getStoredPartnerConfig = () => {
    if (typeof window === 'undefined') return null
    
    try {
        const stored = window.sessionStorage.getItem(PARTNER_CONFIG_KEY)
        if (!stored) return null
        
        const config = JSON.parse(stored) as PartnerConfig
        console.log('[Storage] Retrieved partner config:', config)
        return config
    } catch (e) {
        console.error('[Storage] Failed to get partner config:', e)
        return null
    }
}

export const setStoredPartnerConfig = (config: PartnerConfig | null) => {
    if (typeof window === 'undefined') return
    
    try {
        if (config) {
            console.log('[Storage] Storing partner config:', config)
            window.sessionStorage.setItem(PARTNER_CONFIG_KEY, JSON.stringify(config))
        } else {
            console.log('[Storage] Clearing partner config')
            window.sessionStorage.removeItem(PARTNER_CONFIG_KEY)
        }
    } catch (e) {
        console.error('[Storage] Failed to store partner config:', e)
    }
}
